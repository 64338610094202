<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>
    <div class="my-2" v-else>
      <div class="">
        <h4 class="text-capitalize font-weight-bold">
          Hi, {{ username || "" }}
        </h4>

        <!-- <BaseHeader :title="'Dashboard'"></BaseHeader> -->

        <!-- writer and editor dashboard -->
        <div
          v-if="userType == userTypes.writer || userType == userTypes.editor"
        >
          <BalanceComponent />
        </div>

        <!-- admin dashboard -->

        <div v-else>


          <v-row class="my-3">
            <v-col cols="12" md="3" sm="6">
              <v-card elevation="2" shaped outlined class="card shadow" color="pink  lighten-1" dark>
                <div class="d-flex flex-no-wrap align-center">
                  <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                    <v-avatar >
                      <v-icon size="48" dark>
                        mdi-account
                      </v-icon>
                    </v-avatar>
                      <!-- <v-img :src="item.src"></v-img> -->
                    </v-avatar>
                  <div>
                    <v-card-title
                      class="text-h5 mb-0"
                      v-text="
                        dashboardData.row1 && dashboardData.row1.Clients
                          ? dashboardData.row1['Clients']
                          : '0'
                      "
                    ></v-card-title>

                    <v-card-text v-text="'Clients'"></v-card-text>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card class="shadow" color="success lighten-1" dark>
                <div class="d-flex flex-no-wrap align-center">
                  <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                    <v-avatar >
                      <v-icon size="48" dark>
                        mdi-folder
                      </v-icon>
                    </v-avatar>
                      <!-- <v-img :src="item.src"></v-img> -->
                    </v-avatar>
                  <div>
                    <v-card-title
                      class="text-h5 mb-0"
                      v-text="dashboardData.row1.Files || 0"
                    ></v-card-title>

                    <v-card-text v-text="'Files'"></v-card-text>
                  </div>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" md="3" sm="6">
              <v-card class="shadow" color="primary lighten-1" dark>
                <div class="d-flex flex-no-wrap align-center">
                  <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                    <v-avatar >
                      <v-icon size="48" dark>
                        mdi-file
                      </v-icon>
                    </v-avatar>
                      <!-- <v-img :src="item.src"></v-img> -->
                    </v-avatar>
                  <div>
                    <v-card-title
                      class="text-h5 mb-0"
                      v-text="dashboardData.row1['Pages Written'] || 0"
                    ></v-card-title>

                    <v-card-text v-text="'Pages Writen'"></v-card-text>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <v-card class="shadow" color="indigo lighten-1" dark>
                <div class="d-flex flex-no-wrap align-center">
                  <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                    >
                    <v-avatar >
                      <v-icon size="48" dark>
                        mdi-web
                      </v-icon>
                    </v-avatar>
                      <!-- <v-img :src="item.src"></v-img> -->
                    </v-avatar>
                  <div>
                    <v-card-title
                      class="text-h5 mb-0"
                      v-text="dashboardData.row1.Websites || 0"
                    ></v-card-title>

                    <v-card-text v-text="'Websites'"></v-card-text>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <div class="card my-3 border-0 bg-white shadow">
            <div class="card-header header-elements-inline">
              <h4 class="card-title">Order Stats (No. of orders)</h4>
            </div>
            <div class="card-body px-1 text-right d-flex">
              <line-chart
                v-if="!loading"
                :chartdata="data"
                :options="options"
                class="line-chart"
              ></line-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BalanceComponent from "../../components/payments/BalanceComponent.vue";
import { mapGetters, mapState } from "vuex";
import LineChart from "../../components/charts/LineChart.vue";
import http from "../../modules/http.index";
import BaseLoading from "../../components/_base-loading.vue";
export default {
  name: "Dashboard",
  components: { BalanceComponent, LineChart, BaseLoading },
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("auth", ["user"]),
    username() {
      return `${this.user.first_name || ""} ${this.user.last_name || ""}`;
    },
  },

  data() {
    return {
      loading: true,
      items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
      },
      dashboardData: {},
      data: {
        labels: [],
        datasets: [],
      },
    };
  },

  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    getData() {
      this.loading = true;
      http
        .get("/System/Dashboard/")
        .then((res) => {
          this.dashboardData = res.data.Data;

          Object.entries(this.dashboardData.row2).forEach((yearData, index) => {
            let data = yearData[1].map((monthData) =>
              parseFloat(monthData.no_orders)
            );
            let labels = yearData[1].map((monthData) => monthData.month);
            this.data.labels = labels;
            let color = this.getColor(index); // get chart color based on index
            this.data.datasets.push({
              label: yearData[0], // year name e.g. 2022
              borderColor: color,
              borderWidth: "4",
              fill: false,
              pointBackgroundColor: color,
              pointRadius: "4",
              data: data,
            });
          });
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },

    getColor(index) {
      let color = "#64dfdf";
      if (index == 1) {
        color = "#48bfe3";
      } else if (index == 2) {
        color = "#5390d9";
      } else if (index == 3) {
        color = "#5e60ce";
      } else if (index == 4) {
        color = "#51b0ff";
      }
      return color;
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>
<style scoped>
.line-chart {
  width: 100%;
}
</style>
